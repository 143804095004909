.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* Safari */
  font-family: sans-serif;
  color: #d1d5db;
}

.preloader-icon {
  border: 2px solid var(--loader-border);
  border-radius: 50%;
  border-top: 2px solid #d1d5db;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}