@tailwind base;
@tailwind components;
@tailwind utilities;

table {
  
}

table th {
    @apply text-left tracking-tight font-thin text-[#4b554b] text-nowrap px-2 py-5
}

table tbody tr {
    @apply hover:bg-[#4b554b] hover:bg-opacity-5 hover:cursor-pointer text-[#374151] hover:rounded-lg
}

table tbody tr:nth-last-of-type(even) {
    /* @apply bg-light-black bg-opacity-15 */
}

.bloc-tabs div {
    margin-right: 40px;
    padding: 10px;
    font-size: 16px;
    color: #737B8B;
    cursor: pointer;
}

.tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
    background: white;
    border-bottom: 1px solid #FFC727;
    font-weight: bold;
}

.active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: rgba(88, 147 241);

}

.content-tabs {
    flex-grow: 1;
}

.content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
}

.content h2 {
    padding: 0px 0 5px 0px;
}

.content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
}

.content p {
    width: 100%;
    height: 100%;
}

.active-content {
    display: block;
}

.custom-option-bg {
    background-color: #022A4D;
}

option:hover {
    background-color: transparent;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: inherit;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.custom-underline-amber {
    position: relative;
    text-decoration: none;
}

.custom-underline-amber::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -3px;
    left: -2.2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.custom-underline-amber:hover::before {
    background-color: #FCD34D;
}

.custom-underline-amber:hover::after {
    background-color: #FCD34D;
}

.custom-underline-amber.active {
    color: #FCD34D;
}
